<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "@/views/questionnaires/loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import Executors from "@/components/common/questionnaires/fieldsets/will/Executors";

export default {
  name: "WillExecutors",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    Executors
  },
  data() {
    return {
      name: 'WillExecutors',
      title: "Will Executors",
      url: 'will_full',
      order:
          [
            'Executors'
          ],
      expiryAccessCodeOnFinish: true
    }
  }
};
</script>
